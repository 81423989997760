import { useAPI } from "~/lib/api";
import { Icon } from "~/lib/ui";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Popover } from "~/lib/ui/popover/popover";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { ActivityLogLine } from "~/pages/offers/_cmp/activity-log/activity-log-line";
import { HorizontalDivider } from "~/lib/ui/horizontal-divider";
import ContentLoader from "react-content-loader";

export function ActivityLogSection({ offerId }: { offerId: string }) {
  const api = useAPI();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data, isFetching, error } = useQuery({
    queryKey: ["offerActions", { offerId }],
    queryFn: () => api.getOfferActions({ offerId }),
  });

  const actions = data?.data ?? [];

  // Should never happen, as you won't be able to see this component until the offer has been
  // created, and thus have at least the created action. But TS is happy now.
  if (!actions.length) return null;

  const showAllVisible = !isFetching || actions.length <= 1;

  return (
    <Popover
      triggerAsChild
      onOpenChange={setIsOpen}
      disabled={!showAllVisible}
      config={{ align: "start", side: "bottom", contentClassName: "w-full" }}
      triggerRender={() => (
        <div className="cursor-pointer items-center rounded-lg border bg-white p-4 shadow-sm hover:border-hover">
          {isFetching ? (
            <ContentLoader className="h-5 w-full rounded-md">
              <rect x={0} y={0} width="100%" height="100%"></rect>
            </ContentLoader>
          ) : (
            <div className="flex w-full items-center justify-between">
              <div className="grow-0">
                <ActivityLogLine action={actions[0]} />
              </div>
              {showAllVisible && (
                <div className="flex grow items-center justify-end gap-2 text-sm">
                  <div>{t("common:show_all")}</div>
                  <div>
                    {isOpen ? (
                      <Icon name="chevronUp" className="h-auto w-4" />
                    ) : (
                      <Icon name="chevronDown" className="h-auto w-4" />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    >
      {(close) => (
        <div className="custom-scrollbar max-h-72 overflow-y-auto rounded-lg border bg-white p-4 shadow-sm">
          <div className="pb-4">
            <HorizontalDivider lineClassName="border-shade-200">
              <span className="shrink-0 text-sm font-semibold">{t("common:activity_log")}</span>
            </HorizontalDivider>
          </div>
          <ul role="list" className="">
            {actions.map((action, index) => (
              <li
                key={action.id}
                className={twMerge(
                  index !== actions.length - 1 &&
                    // Create the vertical line between the actions
                    // Calc explanation: 0.75rem is half of the icon width and padding used in ActivityLogLine
                    // 1px is to compensate for half the width of the after:width of 0.5 (2px)
                    // If you change the icon width or padding, you will need to update the rem value
                    // We are doing this differently than Tailwind, because we do not like the relative positioning of this element
                    "after:contents-[''] after:my-1 after:ml-[calc(0.75rem+1px)] after:block after:h-4 after:w-0.5 after:bg-shade-200"
                )}
              >
                <div className="flex items-center">
                  <ActivityLogLine action={action} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Popover>
  );
}
