import { Icon } from "~/lib/ui";
import { Popover } from "~/lib/ui/popover/popover";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskFormState } from "~/pages/planning/_cmp/types";

import { twMerge } from "tailwind-merge";
import { TaskStatus } from "~/lib/planning";

export function StatusSection({ formState }: { formState: TaskFormState }) {
  const { t } = useTranslation();
  const [selectStatusOpen, setSelectStatusOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<TaskStatus>(
    formState.getValue("status") ?? "to_do"
  );

  const handleSelect = (status: TaskStatus) => {
    setSelectedStatus(status);
    formState.setValues({ status });
  };

  const statusStyles = {
    to_do: "bg-shade-400",
    doing: "bg-yellow-500",
    done: "bg-green-500",
  };

  return (
    <div className="flex items-center gap-2">
      <div className="flex grow items-center gap-3">
        <div
          title={t("common:status")}
          className="flex h-8 w-8 shrink-0 items-center justify-center"
        >
          <Icon name="status" size="small" />
        </div>
        <Popover
          config={{
            align: "start",
            sideOffset: 0,
            usePortal: false,
          }}
          onOpenChange={setSelectStatusOpen}
          triggerAsChild={true}
          triggerRender={() => (
            <button className="grow cursor-pointer px-3 py-2 text-shade-500 hover:bg-shade-100 focus:bg-shade-100 focus-visible:bg-shade-100 focus-visible:outline-none">
              <div className="flex items-center gap-2">
                {selectedStatus ? (
                  <div className="flex cursor-pointer items-center gap-2 rounded-lg text-sm hover:bg-shade-100">
                    <div
                      className={twMerge("h-4 w-4 rounded-full", statusStyles[selectedStatus])}
                    ></div>
                    <div className="text-zinc-700">
                      {t(`planning:task.status.${selectedStatus}`)}
                    </div>
                  </div>
                ) : (
                  <div>
                    {t("common:add_entity", {
                      entity: t("common:label", { count: 1 }).toLowerCase(),
                    })}
                  </div>
                )}
                <div>
                  {selectStatusOpen ? <Icon name="chevronUp" /> : <Icon name="chevronDown" />}
                </div>
              </div>
            </button>
          )}
        >
          {(close) => (
            <div className="custom-scrollbar max-h-32 w-full min-w-32 overflow-y-auto border bg-white shadow-sm">
              <button
                onClick={() => {
                  handleSelect("to_do");
                  close();
                }}
                className="flex h-8 w-full cursor-pointer items-center gap-2 px-3 py-2 text-xs hover:bg-shade-100 focus:bg-shade-100"
              >
                <div className={twMerge("h-4 w-4 rounded-full", statusStyles["to_do"])}></div>
                <div>{t(`planning:task.status.to_do`)}</div>
              </button>
              <button
                onClick={() => {
                  handleSelect("doing");
                  close();
                }}
                className="flex h-8 w-full cursor-pointer items-center gap-2 px-3 py-2 text-xs hover:bg-shade-100 focus:bg-shade-100"
              >
                <div className={twMerge("h-4 w-4 rounded-full", statusStyles["doing"])}></div>
                <div>{t(`planning:task.status.doing`)}</div>
              </button>
              <button
                onClick={() => {
                  handleSelect("done");
                  close();
                }}
                className="flex h-8 w-full cursor-pointer items-center gap-2 px-3 py-2 text-xs hover:bg-shade-100 focus:bg-shade-100"
              >
                <div className={twMerge("h-4 w-4 rounded-full", statusStyles["done"])}></div>
                <div>{t(`planning:task.status.done`)}</div>
              </button>
            </div>
          )}
        </Popover>
      </div>
    </div>
  );
}
