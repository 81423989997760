import { Icon } from "~/lib/ui";
import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";
import { Popover } from "~/lib/ui/popover/popover";
import { useTranslation } from "react-i18next";
import { FormTemplateModelWithJsonFormsData } from "@apacta/sdk";

export default function FormSelector({
  templates,
  isFetching,
  onSelect,
  onQueryChange,
}: {
  templates: Array<FormTemplateModelWithJsonFormsData>;
  isFetching: boolean;
  onSelect: (value: FormTemplateModelWithJsonFormsData) => void;
  onQueryChange: (query: string, debounce?: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <Popover
      config={{
        align: "start",
        side: "bottom",
        sideOffset: -40,
        usePortal: true,
        animate: false,
      }}
      triggerAsChild={true}
      onOpenChange={() => {
        onQueryChange("");
      }}
      triggerRender={({ open }) => (
        <button className="h-10 grow cursor-pointer text-sm text-shade-500 hover:bg-shade-100 focus:bg-shade-100 focus:outline-none focus:ring-0 focus-visible:bg-shade-100 focus-visible:outline-none focus-visible:ring-0">
          <div className="flex w-full cursor-pointer items-center gap-2 border-0 bg-transparent px-3 py-2 text-left text-sm ">
            {open ? (
              <>&nbsp;</>
            ) : (
              t("common:add_entity", {
                entity: t("planning:form", { count: 2 }).toLowerCase(),
              })
            )}
            <div>{open ? <Icon name="chevronUp" /> : <Icon name="chevronDown" />}</div>
          </div>
        </button>
      )}
    >
      {(close) => (
        <div className="relative w-[--radix-popover-trigger-width]">
          <div>
            <input
              placeholder={t("common:search_x", {
                x: t("planning:form", { count: 2 }).toLowerCase(),
              })}
              type="text"
              onFocus={(e) => e.currentTarget.select()}
              onChange={(e) => onQueryChange(e.currentTarget.value, true)}
              className="h-10 w-full cursor-pointer border-0 bg-transparent px-3 py-2 text-sm focus:cursor-auto focus:outline-none focus:ring-0 focus-visible:bg-shade-100 focus-visible:outline-none focus-visible:ring-0"
            />
          </div>
          <div className="custom-scrollbar max-h-32 overflow-y-auto border bg-white shadow-sm">
            {isFetching ? (
              <div className="px-4 py-2">
                <ContentLoader className={twMerge("h-6 w-full")}>
                  <rect x={0} y={0} width="80vw" height="100vh"></rect>
                </ContentLoader>
              </div>
            ) : templates.length ? (
              <>
                {templates.map((template) => (
                  <div
                    key={`form-template-${template.id}`}
                    onClick={() => {
                      onSelect(template);
                      close();
                    }}
                    className="group flex cursor-pointer items-center gap-2 px-3 py-2 text-sm hover:bg-shade-100"
                  >
                    <div>{template.name}</div>
                  </div>
                ))}
              </>
            ) : (
              <div className={twMerge("px-4 py-2 text-shade-400")}>{t("common:no_results")}</div>
            )}
          </div>
        </div>
      )}
    </Popover>
  );
}
