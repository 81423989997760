import { CSSProperties, forwardRef, ReactNode, Ref } from "react";
import { twMerge } from "tailwind-merge";

export const SelectionContent = forwardRef(function SelectionPopin(
  {
    children,
    style,
  }: {
    children: ReactNode;
    style?: CSSProperties;
  },
  ref: Ref<HTMLUListElement>
) {
  return (
    <div className="max-h-80 w-full min-w-[20rem] overflow-y-auto rounded-md border  border-gray-300 bg-white text-left shadow-md">
      <ul
        style={style}
        data-combobox={true}
        role="dialog"
        ref={ref}
        className={twMerge("custom-scrollbar w-full p-2")}
      >
        {children}
      </ul>
    </div>
  );
});
