import { useRef, useState } from "react";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { useMount } from "~/lib/lifecycle-helpers";
import { usePlanning } from "~/lib/planning";
import { dateInRange } from "~/lib/utils/date/date-utils";
import { ScrollLayer } from "./drag/scroll-layer";
import { useOutletContext } from "react-router";
import { OutletContext } from "./types";
import { twMerge } from "tailwind-merge";
import PlanningTableRow from "~/pages/planning/_cmp/table/planning-table-row";
import PlanningTableRowUnsassigned from "~/pages/planning/_cmp/table/planning-table-row-unsassigned";

export function PlanningViewSingleDay() {
  const { isDragging } = useOutletContext<OutletContext>();
  const [collapsed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { isLoadingCards, users, cardStateViews, dateRange, selectedDate, sortedUsers } =
    usePlanning();

  useMount(() => {
    setLoading(true);
  });

  // Fix flickering when switching between days that have cards, and days that do not
  useDebounce(
    () => {
      if (!isLoadingCards) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    },
    [
      cardStateViews.plannedUnassigned,
      cardStateViews.plannedAssigned,
      selectedDate,
      isLoadingCards,
    ],
    1
  );

  const boundingRef = useRef<HTMLDivElement | null>(null);

  const matchDate = (date?: Date | null, from?: Date, to?: Date): boolean => {
    if (date && from && to) {
      return dateInRange(date, from, to, { ignoreTimestamp: true });
    }

    return false;
  };

  return (
    <div
      ref={boundingRef}
      className={twMerge(
        "custom-scrollbar relative flex flex-grow flex-col gap-4 overflow-y-auto overflow-x-hidden scroll-smooth pb-4"
      )}
    >
      <ScrollLayer boundingRef={boundingRef} isDragging={isDragging} disableHorizontal={true} />

      <table className="w-fit table-fixed border-separate border-spacing-0 border-b">
        <tbody>
          <PlanningTableRowUnsassigned isLoading={loading || isLoadingCards} />
          {sortedUsers.map((user, idx) => (
            <PlanningTableRow
              key={`employee-week-${user.id}-${idx}-1`}
              user={user}
              isLoading={loading || isLoadingCards}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
