import { UsersPriceGroup } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";

export function UsersPriceGroups({
  onChange,
  initialPriceGroupId,
  hideLabel = false,
}: {
  onChange: (condition: UsersPriceGroup | undefined | null) => void;
  initialPriceGroupId?: string;
  hideLabel?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();

  const { data } = useSuspenseQuery({
    queryKey: ["users_price_groups"],
    queryFn: () => api.usersPriceGroupsList({}),
  });

  const usersPriceGroups = data?.data ?? [];

  const manageSelection = function (id?: string) {
    if (!id) {
      onChange?.(null);
      return;
    }

    const userPriceGroup = usersPriceGroups?.find((group) => group.id === id);
    onChange?.(userPriceGroup);
  };

  return (
    <>
      <SelectionCombobox
        label={hideLabel ? "" : t("common:users_price_groups")}
        defaultValue={initialPriceGroupId}
        data={usersPriceGroups}
        valueFn={(e) => e.id ?? ""}
        labelFn={(e) => `${e.name} (${e.salePrice})`}
        onSelectValue={manageSelection}
      />
    </>
  );
}
