import { useEffect, useRef } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../command/command";
import { DefaultCreateComponent } from "./defaults/DefaultCreateComponent";
import { DefaultEmptyComponent } from "./defaults/DefaultEmptyComponent";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useSelectionCombobox } from "./context";
import { twMerge } from "tailwind-merge";
import { Button, Icon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { testIdentifiers } from "./test-ids";

// What we render inside the Popover
export function SelectionComboboxContent<T>() {
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);
  const {
    selectedItems,
    filteredData,
    open,
    searchPlaceholder,
    renderItem,
    onCreateNew,
    disabled,
    onQueryChange,
    labelFn,
    keyFn,
    valueFn,
    isSelected,
    multiple,
    onConfirmMultiSelect,
    loading,
    onValueChange,
    onSelectAll,
    onClearSelection,
    enableSelectAll,
    contentClassName,
  } = useSelectionCombobox<T>();

  const virtual = useVirtualizer({
    count: filteredData.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => 60,
    overscan: 10,
  });
  const vData = virtual.getVirtualItems();

  // Measure as soon as the dialog opens. Otherwise it measures an invisible element
  useEffect(() => {
    if (open) {
      virtual.measure();
    }
  }, [open]);

  function handleQueryChange(query: string) {
    virtual.scrollToOffset(0); // scrolls to top on search
    onQueryChange(query);
  }

  return (
    <div
      className={twMerge("w-[--radix-popover-trigger-width]", contentClassName)}
      data-testid={testIdentifiers.content}
    >
      <Command
        // Command is a helpful wrapper for managing lists, selections and accessability
        shouldFilter={false}
      >
        <CommandInput
          placeholder={searchPlaceholder ?? t("common:pick_or_search")}
          disabled={disabled}
          onValueChange={handleQueryChange}
          className="border-none focus:outline-none focus:ring-transparent"
        />
        {loading && (
          <div className="absolute left-0 top-0 h-[0.1rem] animate-loading rounded-md bg-blue-400 repeat-infinite" />
        )}
        <hr />
        <CommandList>
          {!onCreateNew && (
            <CommandEmpty>
              <DefaultEmptyComponent />
            </CommandEmpty>
          )}

          <CommandGroup
            className="custom-scrollbar max-h-80 min-h-40 w-full divide-y overflow-y-auto"
            ref={scrollRef}
          >
            <div
              style={{
                position: "relative",
                height: virtual.getTotalSize(),
                width: "100%",
              }}
            >
              {vData.map((vItem, idx) => {
                const d = filteredData[vItem.index];

                return (
                  <CommandItem
                    ref={virtual.measureElement}
                    keywords={[labelFn(d)]}
                    key={vItem.key ?? keyFn?.(d) ?? idx}
                    value={valueFn(d)}
                    onSelect={onValueChange}
                    className="absolute left-0 top-0 my-1 w-full py-2 pl-2"
                    style={{
                      transform: `translateY(${vItem.start ?? 0}px)`,
                    }}
                    data-index={vItem.index}
                  >
                    <Icon
                      name="selectedCheck"
                      className={twMerge(
                        "mr-2 h-4 w-4 shrink-0",
                        isSelected(d) ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {renderItem ? renderItem(d) : labelFn(d)}
                  </CommandItem>
                );
              })}
            </div>
            {onCreateNew && <DefaultCreateComponent onCreate={onCreateNew} />}
          </CommandGroup>
        </CommandList>
        {multiple && (
          <div className="flex justify-end gap-4 border-t border-t-shade-300">
            {enableSelectAll && (
              <Button
                onClick={onSelectAll}
                className="font-medium hover:text-hover focus:underline focus:ring-0 focus:ring-offset-0 "
              >
                {t("ui:select_all")}
              </Button>
            )}

            {selectedItems.length > 0 && (
              <Button
                onClick={onClearSelection}
                className="font-medium hover:text-hover focus:underline focus:ring-0 focus:ring-offset-0 "
              >
                {t("ui:clear_selection")}
              </Button>
            )}

            <Button
              onClick={onConfirmMultiSelect}
              onKeyDown={(e) => {
                e.preventDefault();
                if (e.key === "Enter") onConfirmMultiSelect();
              }}
              className="font-medium text-green-800 hover:text-hover focus:underline focus:ring-0 focus:ring-offset-0 "
            >
              {t("common:confirm")}
            </Button>
          </div>
        )}
      </Command>
    </div>
  );
}
