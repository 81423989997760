import { ReactNode, useState } from "react";
import { Spinner } from "~/lib/ui/spinner";
import { twMerge } from "tailwind-merge";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

type ItemRender<ItemType extends { id?: string }> = ({
  item,
  index,
  isSelected,
  triggerSelect,
}: {
  item: ItemType;
  index: number;
  isSelected: boolean;
  triggerSelect: () => void;
}) => ReactNode;

type MultiselectProps<ItemType extends { id?: string }> = {
  filterRender?: () => ReactNode;
  descriptionRender?: () => ReactNode;
  buttonRender?: (items: Array<ItemType>) => ReactNode;
  itemRender: ItemRender<ItemType>;
  items: Array<ItemType>;
  initialSelected?: Array<ItemType>;
  isLoading?: boolean;
  divideItems?: boolean;
};

/**
 * Renders a filterable list of elements
 * @param headerRender
 * @param itemRender
 * @param items
 * @constructor
 */
export function FilterList<ItemType extends { id?: string }>({
  filterRender,
  descriptionRender,
  buttonRender,
  itemRender,
  items,
  initialSelected,
  isLoading = false,
  divideItems = false,
}: MultiselectProps<ItemType>) {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<Array<ItemType>>(initialSelected || []);

  const toggleItem = (item: ItemType) => {
    if (selectedItems.flatMap((i) => i.id).includes(item.id)) {
      setSelectedItems((prevState) => [...prevState].filter((i) => i.id !== item.id));
    } else {
      setSelectedItems((prevState) => [...prevState, item]);
    }
  };

  return (
    <>
      {filterRender && (
        <>
          <div>{filterRender()}</div>
          <hr></hr>
        </>
      )}
      {descriptionRender && <div>{descriptionRender()}</div>}
      <div
        className={twMerge(
          "custom-scrollbar flex flex-grow flex-col gap-2 overflow-y-scroll",
          divideItems ? "divide-y" : ""
        )}
      >
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        ) : (
          <>
            {items.map((item, index) => (
              <div key={`filterlist-${index}`} className={twMerge(divideItems ? "pt-2" : "")}>
                {itemRender({
                  item,
                  index,
                  isSelected: selectedItems.flatMap((i) => i.id).includes(item.id),
                  triggerSelect: () => toggleItem(item),
                })}
              </div>
            ))}
            {items.length === 0 && (
              <div className="flex h-full flex-col items-center justify-center gap-1">
                <MagnifyingGlassIcon className="h-12 w-12" />
                <span className="text-gray-400">{t("common:no_results")}</span>
              </div>
            )}
          </>
        )}
      </div>
      {buttonRender && (
        <>
          <hr></hr>
          <div>{buttonRender(selectedItems)}</div>
        </>
      )}
    </>
  );
}
