import { forwardRef, JSX, useEffect, useImperativeHandle, useState } from "react";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";
import {
  $getRoot,
  $getSelection,
  $setSelection,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  INSERT_LINE_BREAK_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  LexicalEditor,
} from "lexical";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { useMount } from "~/lib/lifecycle-helpers";
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  HEADING,
  ITALIC_UNDERSCORE,
  ORDERED_LIST,
  UNORDERED_LIST,
} from "@lexical/markdown";
import { VARIABLE_NODE_TRANSFORMER } from "~/lib/ui/rich-text-editor/editor/plugins/variable-plugin/VariableNode";
import { twMerge } from "tailwind-merge";
import EditorFooter from "~/lib/ui/rich-text-editor/editor/editor-footer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";

type RichTextEditorProps = {
  initialData?: string; //Markdown syntax
  variables?: { [key: string]: () => JSX.Element };
  limitHeight?: boolean;
  id: string;
  onChange?: (text: string, html: string, markdown: string) => void;
  autofocus?: boolean;
  preview?: boolean;
  characterLimit?: number;
  isRichText?: boolean;
};
export type RichTextEditorBodyRef = {
  setEditorMarkdown: (data: string, replace?: boolean) => void;
  getEditorMarkdown: () => Promise<string>;
  getEditorHTML: () => Promise<string>;
};

const TRANSFORMERS = [
  BOLD_STAR,
  ITALIC_UNDERSCORE,
  BOLD_ITALIC_UNDERSCORE,
  HEADING,
  ORDERED_LIST,
  UNORDERED_LIST,
];

export const EditorBody = forwardRef<RichTextEditorBodyRef, RichTextEditorProps>(
  function EditorBodyInner(
    { initialData, limitHeight, id, onChange, preview = false, characterLimit, isRichText = true },
    ref
  ) {
    const [editor] = useLexicalComposerContext();
    const [editorHTML, setEditorHTML] = useState<string>("");
    const [editorTextContent, setEditorTextContent] = useState<string>("");
    const [editorMarkdownContent, setEditorMarkdownContent] = useState<string>("");
    const [mounted, setMounted] = useState<boolean>(false);
    const [previewMode, setPreviewMode] = useState<boolean>(preview);
    const [characterCount, setCharacterCount] = useState<number>(0);

    useMount(() => {
      editor.setEditable(!previewMode);
      setMounted(true);

      /**
       * Overwrite the default line break command to insert a paragraph instead
       * This is done to avoid older software pasting content with only single lines breaks.
       * It also prevents users from creating single line breaks by pressing enter, in the editor.
       * https://github.com/Apacta/issues/issues/1152
       */
      editor.registerCommand(
        INSERT_LINE_BREAK_COMMAND,
        () => {
          return editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
        },
        COMMAND_PRIORITY_CRITICAL
      );

      if (initialData) {
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);

        editor.update(() => {
          if (isRichText) {
            $convertFromMarkdownString(initialData, [...TRANSFORMERS, VARIABLE_NODE_TRANSFORMER]);
          } else {
            $getSelection()?.insertText(initialData);
          }

          $setSelection(null);
        });
      }
    });

    // Not everything is in perfect sync, so we need to handle stuff like preview mode changing
    useEffect(() => {
      if (preview !== previewMode) {
        setPreviewMode(preview);
        editor.setEditable(!preview);
      }

      return () => undefined;
    }, [preview]);

    // If we are in preview mode, we kinda need this component to be controlled
    useEffect(() => {
      if (previewMode && !!initialData) {
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        editor.update(() => {
          if (isRichText) {
            $convertFromMarkdownString(initialData, [...TRANSFORMERS, VARIABLE_NODE_TRANSFORMER]);
          } else {
            $getSelection()?.insertText(initialData);
          }

          setCharacterCount(initialData.length);
        });
      }
      return () => undefined;
    }, [initialData]);

    useImperativeHandle(ref, () => ({
      setEditorMarkdown(data: string, replace = true): void {
        if (replace) {
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        }
        editor.update(() => {
          if (replace) {
            $getRoot().select();
          }
          $convertFromMarkdownString(data ?? "", [...TRANSFORMERS, VARIABLE_NODE_TRANSFORMER]);
        });
      },
      async getEditorMarkdown(): Promise<string> {
        return new Promise<string>((resolve) => {
          editor.update(() => {
            resolve($convertToMarkdownString([...TRANSFORMERS, VARIABLE_NODE_TRANSFORMER]));
          });
        });
      },
      async getEditorHTML(): Promise<string> {
        return new Promise<string>((resolve) => {
          editor.update(() => {
            resolve($generateHtmlFromNodes(editor));
          });
        });
      },
    }));

    const handleOnChange = (e: LexicalEditor): void => {
      e.update(() => {
        const html = $generateHtmlFromNodes(editor);
        setEditorTextContent($getRoot().getTextContent());
        setEditorHTML(html);
        if (isRichText) {
          setEditorMarkdownContent(
            $convertToMarkdownString([...TRANSFORMERS, VARIABLE_NODE_TRANSFORMER])
          );
        }
        setCharacterCount(
          $convertToMarkdownString([...TRANSFORMERS, VARIABLE_NODE_TRANSFORMER]).length
        );
      });
    };

    useDebounce(
      () => {
        onChange?.(editorTextContent, editorHTML, editorMarkdownContent);
      },
      [editorTextContent, editorHTML, editorMarkdownContent],
      500
    );

    return (
      <div className="editor-inner">
        {isRichText ? (
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                id={id}
                className={twMerge(
                  "custom-scrollbar resize-none  text-base outline-0",
                  limitHeight ? "max-h-96" : "",
                  previewMode ? "py-2" : "min-h-[10em] overflow-y-scroll px-3 py-2"
                )}
              />
            }
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary}
          />
        ) : (
          <PlainTextPlugin
            contentEditable={
              <ContentEditable
                id={id}
                className={twMerge(
                  "custom-scrollbar resize-none  text-base outline-0",
                  limitHeight ? "max-h-96" : "",
                  previewMode ? "py-2" : "min-h-[10em] overflow-y-scroll px-3 py-2"
                )}
              />
            }
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary}
          />
        )}
        <OnChangePlugin
          ignoreSelectionChange={!mounted}
          onChange={(eState, e) => handleOnChange(e)}
        />
        <ClearEditorPlugin />
        <HistoryPlugin />
        {isRichText && <ListPlugin />}
        {characterLimit && !preview && (
          <EditorFooter characterCount={characterCount} characterLimit={characterLimit} />
        )}
      </div>
    );
  }
);

EditorBody.displayName = "EditorBody";
