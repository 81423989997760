import { FrontpageFeedDTO } from "@apacta/sdk";
import UserIdAvatar from "~/lib/ui/avatar/user-id-avatar";
import { NavLink } from "react-router";
import { linkToEmployee, linkToProject } from "~/lib/utils";
import { formatDate } from "~/lib/utils/date";
import { useTranslation } from "react-i18next";
import { Card, CardField } from "./static/card";
import { CardHeader } from "./static/card-header";
import colors from "tailwindcss/colors";
import { Button, Dialog, getIcon } from "~/lib/ui";
import { useState } from "react";
import { WallPostItem } from "~/pages/projects/_cmp/wall-post-item";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";

export const WallPostCard = ({ data }: { data: FrontpageFeedDTO }) => {
  const [wallPostOpen, setWallPostOpen] = useState<boolean>(false);
  const files = data.formFields?.images ?? []; // show max 4 images
  const { t, i18n } = useTranslation();

  const api = useAPI();

  const postQ = useQuery({
    queryKey: ["wall-post", data.wallPost?.id],
    queryFn: () => api.getWallPost({ postId: data.wallPost?.id ?? "" }),
  });

  const wallPostItem = postQ.data?.data;

  const fields: Array<CardField> = [
    {
      label: t("common:project", { count: 1 }),
      render: () => (
        <NavLink to={linkToProject(data.project?.id ?? "")} className="hover:underline">
          {data.project?.fullName}
        </NavLink>
      ),
    },
    {
      label: t("common:employee", { count: 1 }),
      render: () => (
        <NavLink to={linkToEmployee(data.employee.id ?? "")} className="hover:underline">
          <div className="flex">{`${data.employee.firstName} ${data.employee.lastName}`}</div>
        </NavLink>
      ),
    },
    {
      label: t("common:date"),
      render: () => formatDate(data.date, i18n.resolvedLanguage),
    },
    {
      render: () => (
        <>
          {wallPostItem && (
            <Dialog
              className="md:max-w-4xl"
              open={wallPostOpen}
              onOpenChange={setWallPostOpen}
              render={({ onClose: close }) => (
                <>
                  <DialogHeader title="Vægopslag" Icon={getIcon("wall")} className="bg-cyan-500" />
                  <div className="custom-scrollbar max-h-auto my-4 p-4 md:max-h-[50vh] md:overflow-y-auto">
                    <ul role="list" className="">
                      <li>
                        <div className="relative flex items-start space-x-3">
                          <WallPostItem
                            item={wallPostItem}
                            onRefresh={() => postQ.refetch()}
                            readonly
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <DialogFooter
                    hideCancel={true}
                    primary={{
                      label: t("common:close"),
                      onClick: close,
                    }}
                    onClose={close}
                  />
                </>
              )}
            />
          )}
          <div className="flex flex-col gap-2">
            <p className="line-clamp-3 whitespace-pre-line px-2 pt-2 text-sm">
              {data.wallPost?.message ?? "N/A"}
            </p>
            {wallPostItem && (
              <div className="flex justify-end">
                <Button onClick={() => setWallPostOpen(true)} variant="secondary" size="small">
                  {`${t("common:open")} ${t("common:wall_post").toLowerCase()}`}
                </Button>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];

  return (
    <Card
      renderHeader={() => (
        <CardHeader
          title={t("common:wall_post")}
          icon="wall"
          color={colors["cyan"][500]}
          renderIdentifier={() => (
            <UserIdAvatar
              userId={data.employee.id}
              className="flex h-7 w-7 items-center justify-center text-xs"
            />
          )}
        />
      )}
      fields={fields}
      files={files}
    />
  );
};
