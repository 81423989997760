import { useState } from "react";
import { useDebounce } from "../debounce/use-debounce";
import { TabHeader, TabHeaderItem } from "../ui/tabs/tab-header";
import VendorProductFilter from "./components/vendor-product-filter";
import { Button, getIcon } from "../ui";
import { useTranslation } from "react-i18next";
import ExpenseLineSection from "./components/expense-line-section";
import VendorProductSection from "./components/vendor-product-section";
import ProductSection from "./components/product-section";
import { ExpenseLine, Product, Vendor, VendorProduct } from "@apacta/sdk";
import { useMe } from "~/lib/auth/use-me";
import { Input } from "~/lib/ui/form-elements";

type ProductTypeProduct = {
  type: "product";
  item: Product;
};

type ProductTypeExpenseLine = {
  type: "expense_line";
  item: ExpenseLine;
};

type ProductTypeVendorProduct = {
  type: "vendor_product";
  item: VendorProduct;
};
export type ProductItem = ProductTypeProduct | ProductTypeExpenseLine | ProductTypeVendorProduct;

export const ProductSearchContent = ({
  defaultTab,
  defaultQuery = "",
  defaultVendor,
  onClose,
  onSelect,
  onTabChange,
  onQueryChange,
  onVendorChange,
  options,
}: {
  defaultTab?: TabHeaderItem;
  defaultQuery?: string;
  defaultVendor?: Vendor;
  onClose?: () => void;
  onSelect?: (item: ProductItem) => void;
  onTabChange?: (tab: TabHeaderItem) => void;
  onQueryChange?: (query: string) => void;
  onVendorChange?: (vendor: Vendor | undefined) => void;
  options?: {
    allowedProductTypes?: Array<string>;
  };
}) => {
  const { t } = useTranslation();
  const { companySettings, user } = useMe();

  const defaultAllowedProductTypes = ["product", "expense_line", "vendor_product"];
  const allowedProductTypes = options?.allowedProductTypes ?? defaultAllowedProductTypes;

  let tabs: Array<TabHeaderItem> = [
    {
      id: "product",
      label: t("products:product_type.own_product", { count: 2 }),
      Icon: getIcon("product"),
    },
    {
      id: "expense_line",
      label: t("products:product_type.expense_line", { count: 2 }),
      Icon: getIcon("expense"),
    },
    {
      id: "vendor_product",
      label: t("products:product_type.vendor_product", { count: 2 }),
      Icon: getIcon("product"),
    },
  ];

  tabs = tabs.filter((tab) => allowedProductTypes?.find((allowedType) => allowedType === tab.id));
  const [selectedTab, setSelectedTab] = useState<TabHeaderItem>(defaultTab ?? tabs[0]);

  const [productSearchInput, setProductSearchInput] = useState<string>(defaultQuery);
  const [productSearchQuery, setProductSearchQuery] = useState<string>(defaultQuery);

  const [selectedVendor, setSelectedVendor] = useState<Vendor | undefined>(defaultVendor);

  const userConsideredAdmin =
    user.roles.find((role) => role.identifier === "company") !== undefined ||
    user.adminAccess === "access_to_admin";
  const companyShowSellingPricesToEmployees = companySettings.showSellingPriceApp;

  const handleVendorChange = (vendor?: Vendor) => {
    setSelectedVendor((prevState) => (prevState?.id === vendor?.id ? undefined : vendor));
    onVendorChange?.(vendor);
  };

  useDebounce(
    () => {
      setProductSearchQuery(productSearchInput);
      onQueryChange?.(productSearchInput);
    },
    [productSearchInput],
    500
  );

  return (
    <>
      <div>
        <Input
          placeholder={`${t("common:search")} ${selectedTab.label.toLowerCase()}`}
          defaultValue={defaultQuery}
          onChange={(e) => setProductSearchInput(e.currentTarget.value)}
        />
      </div>

      {/* Tabs section */}
      <TabHeader
        tabs={tabs}
        selectedTab={selectedTab}
        onSelect={(tab) => {
          setSelectedTab(tab);
          onTabChange?.(tab);
        }}
      />

      {/* Vendor filter for vendor products and expense lines */}
      {selectedTab.id !== "product" && (
        <div className="inline-flex">
          <VendorProductFilter
            selectedVendor={selectedVendor}
            selectedTab={selectedTab}
            onSelect={handleVendorChange}
          />
        </div>
      )}

      {/* Main content section */}
      <div className="custom-scrollbar flex flex-grow overflow-auto">
        <div className="flex flex-grow">
          {selectedTab.id === "product" && (
            <ProductSection
              searchQuery={productSearchQuery}
              onSelect={(product) => onSelect?.({ item: product, type: "product" })}
              showSellingPrice={userConsideredAdmin || companyShowSellingPricesToEmployees}
              showBuyingPrice={userConsideredAdmin}
              showGrossPrice={userConsideredAdmin}
            />
          )}
          {selectedTab.id === "expense_line" && (
            <ExpenseLineSection
              searchQuery={productSearchQuery}
              selectedVendor={selectedVendor}
              onSelect={(expenseLine) => onSelect?.({ item: expenseLine, type: "expense_line" })}
              showSellingPrice={userConsideredAdmin || companyShowSellingPricesToEmployees}
              showBuyingPrice={userConsideredAdmin}
              showGrossPrice={userConsideredAdmin}
            />
          )}
          {selectedTab.id === "vendor_product" && (
            <VendorProductSection
              searchQuery={productSearchQuery}
              selectedVendor={selectedVendor}
              onSelect={(vendorProduct) =>
                onSelect?.({ item: vendorProduct, type: "vendor_product" })
              }
              showSellingPrice={userConsideredAdmin || companyShowSellingPricesToEmployees}
              showBuyingPrice={userConsideredAdmin}
              showGrossPrice={userConsideredAdmin}
            />
          )}
        </div>
      </div>

      <div className="flex w-full flex-col items-end justify-end">
        <hr className="w-full" />
        <Button onClick={onClose} className=" underline hover:text-hover">
          {t("common:cancel")}
        </Button>
      </div>
    </>
  );
};
