import { ChangeEvent, MouseEventHandler, ReactNode, useMemo, useState } from "react";
import { FilterGroupProps, FilterSelectionProp, SupportedPrimitives } from "./filter-group";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";

export function FilterGroupSelectionList<ItemType>({
  items,
  allowMultiple,
  value,
  valueFn,
  filterFn,
  onConfirm,
  onClear,
  onQueryChange,
  renderSelectionItem,
}: {
  items: Array<ItemType>;
  allowMultiple?: boolean;
  value?: FilterGroupProps<ItemType>["value"];
  filterFn?: FilterSelectionProp<ItemType>["filterFn"];
  valueFn: FilterSelectionProp<ItemType>["valueFn"];
  onConfirm: (selection: Array<ItemType>) => void;
  renderSelectionItem?: (item: ItemType) => ReactNode;
  onClear?: MouseEventHandler<HTMLElement>;
  onQueryChange?: (query: string) => void;
}) {
  const { t } = useTranslation();
  const [filterQuery, setFilterQuery] = useState("");
  let defaultValues = value;
  if (defaultValues === undefined) defaultValues = [];
  if (!Array.isArray(defaultValues)) defaultValues = [defaultValues];
  const [localValues, setLocalValues] = useState<Array<SupportedPrimitives>>(defaultValues);

  const filteredItems = useMemo(() => {
    if (!items) return [];

    return items.filter((item) => {
      if (!filterFn) return true;
      if (!filterQuery) return true;
      return filterFn?.(item, filterQuery) ?? false;
    });
  }, [items, filterQuery]);

  function handleItemSelect(item: ItemType) {
    const v = valueFn(item);
    if (!allowMultiple) {
      // Single selection
      onConfirm([item]); // Confirm and close when not selecting multiple
      return;
    }
    if (localValues.includes(v)) {
      // Removing selection
      setLocalValues((prev) => prev.filter((it) => it !== v));
    } else {
      // Adding selection
      setLocalValues((prev) => [...prev, v]);
    }
  }

  function checkIfSelected(item: ItemType) {
    return localValues.includes(valueFn(item));
  }

  const handleClear: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    setLocalValues([]);
    setFilterQuery("");
    onClear?.(e);
  };

  function handleConfirmSelection() {
    const selectedItems = items.filter((item) => localValues.includes(valueFn(item)));
    onConfirm(selectedItems);
  }

  function handleQueryChange(e: ChangeEvent<HTMLInputElement>) {
    setFilterQuery(e.target.value);
    onQueryChange?.(e.target.value);
  }

  return (
    <>
      <div
        className="custom-scrollbar flex max-h-[12rem] flex-col overflow-y-auto"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleConfirmSelection();
          }
        }}
      >
        {(filterFn || onQueryChange) && (
          <input
            className="border-gray-200 px-4 py-2 outline-none focus:border-gray-300 focus:ring-0"
            placeholder={t("common:search")}
            defaultValue={filterQuery}
            onChange={handleQueryChange}
          />
        )}
        {filteredItems.length > 0 ? (
          filteredItems.map((item, idx) => (
            <div
              key={idx}
              tabIndex={0}
              className="flex w-full cursor-pointer flex-row items-center justify-between px-4 py-2 align-middle outline-none hover:bg-hover hover:text-white focus:bg-hover focus:text-white"
              role="button"
              onClick={() => handleItemSelect(item)}
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.preventDefault();
                  handleItemSelect(item);
                }
              }}
            >
              <div>{renderSelectionItem?.(item)}</div>
              {checkIfSelected(item) ? <CheckIcon className="h-4 w-4" /> : null}
            </div>
          ))
        ) : (
          <div className={twMerge("hidden px-4 py-2 text-shade-400", filterQuery && "block")}>
            {t("common:no_results")}
          </div>
        )}
      </div>
      <div className="flex justify-between bg-gray-100 px-4 py-2">
        <button className="outline-none focus:text-hover" onClick={handleClear}>
          {t("common:show_all")}
        </button>
        {allowMultiple && (
          <button
            className="font-semibold outline-none focus:text-hover"
            onClick={handleConfirmSelection}
          >
            {t("common:apply")}
          </button>
        )}
      </div>
    </>
  );
}
