import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { InvoiceActivityLogItem } from "./activity-log-content-item";
import { HorizontalDivider } from "~/lib/ui/horizontal-divider";
import { twMerge } from "tailwind-merge";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { CACHE_INVOICES } from "..";

export function InvoiceActivityLogContent(props: { invoiceId: string; className?: string }) {
  const api = useAPI();
  const { t } = useTranslation();
  const dataQ = useQuery({
    queryFn: () => api.getInvoiceActivityLog({ invoiceId: props.invoiceId }),
    queryKey: [CACHE_INVOICES, props.invoiceId, "activity-log"],
  });

  const events = dataQ.data?.data ?? [];

  return (
    <div
      className={twMerge(
        "custom-scrollbar max-h-72 overflow-y-auto rounded-lg border bg-white p-4 shadow-sm",
        props.className
      )}
      style={{
        minWidth: "var(--radix-popover-trigger-width)",
      }}
    >
      <div className="pb-4">
        <HorizontalDivider lineClassName="border-shade-200">
          <span className="shrink-0 text-sm font-semibold">{t("common:activity_log")}</span>
        </HorizontalDivider>
      </div>
      <ul role="list">
        {dataQ.isLoading && <SkeletonLoader template="bulletList" className="space-y-2" />}
        {events.map((al, idx) => (
          <li
            key={idx}
            className={twMerge(
              idx !== events.length - 1 &&
                // Create the vertical line between the actions
                // Calc explanation: 0.75rem is half of the icon width and padding used in ActivityLogLine
                // 1px is to compensate for half the width of the after:width of 0.5 (2px)
                // If you change the icon width or padding, you will need to update the rem value
                // We are doing this differently than Tailwind, because we do not like the relative positioning of this element
                "after:contents-[''] after:my-1 after:ml-[calc(0.75rem+1px)] after:block after:h-4 after:w-0.5 after:bg-shade-200"
            )}
          >
            <div className="flex items-center">
              <InvoiceActivityLogItem key={idx} item={al} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
